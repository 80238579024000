html, body {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-color: #f8f8f8;
}

.rv-xy-plot__inner {
    overflow: inherit;
}
