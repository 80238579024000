.draggedOver {
  position: relative;
  margin-left: 80px;

  // the before pseudo element fills the margin created of the real element
  // this is important so the element doesn't jump when the dragged element is held too far left
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 80px;
    transform: translateX(-80px);
  }
}

.dragging {
  opacity: 0.5;
}
